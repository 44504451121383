<template>
  <v-container fluid>
    <!-- <Breadcrumbs /> -->
    <v-card elevation="0" style="margin-bottom:10px">
      <v-layout row wrap px-5>
        <v-flex
          xs7
          sm9
          md10
          style="text-align: center;
                 padding: 5px;"
        >
          <v-text-field
            v-model="token"
            label="API Token"
            outlined
            readonly
            style="padding: 8px 0;"
          ></v-text-field>
        </v-flex>

        <v-flex xs5 sm3 md2 style="text-align: center; padding: 5px; ">
          <v-btn
            style="margin-left:10px; margin-top:8px; color:primary; padding: 28px ;"
            mt-5
            @click="copy(token)"
            >COPY TOKEN</v-btn
          >
        </v-flex>
      </v-layout>

      <v-snackbar v-model="snackbar" :bottom="true" :right="true" timeout="2000"
        >Copied!</v-snackbar
      >
    </v-card>

    <v-card elevation="0" style="margin-bottom:10px">
      <v-layout mx-1 row style="padding: 2%;">
        <v-flex xs12 sm12 md12 style="padding-top: 20px;">
          <h3>Godial API</h3>
        </v-flex>

        <v-flex>
          <p>
            Extend the features of GoDial and integrate with your existing
            solution using the GoDial API.
          </p>
        </v-flex>
      </v-layout>

      <v-layout xs12 sm12 md12 style="padding-top: 20px;">
        <v-flex mt-4 mx-5>
          <h4>Philosophy of Operation</h4>

          <p>
            GoDial generates a Token for your company, which is listed above and
            is available only from the manager account. This token has to be
            passed on every call in the Authorization header.
          </p>

          <p>
            GoDial’s CRM is based on Lists and Contacts. Each list is a
            collection of contacts. Similarly, Teams are a collection of
            members.
          </p>

          <p>
            To get started, obtain your token. You can start by adding members,
            adding a list, assigning a list to a member, then adding contacts to
            the list, finally viewing reports.
          </p>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex mt-4 mx-5>
          <h4>Example flow</h4>
          <br />
          <v-expansion-panels focusable>
            <v-expansion-panel>
              <v-expansion-panel-header>Add a Team</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout row mt-4>
                  <v-flex
                    xs4
                    s3
                    m2
                    style="display:flex; justify-content:center;"
                  >
                    <v-btn
                      style="padding:20px;margin-top:4px;"
                      dark
                      color="primarygrad"
                    >
                      POST
                    </v-btn>
                  </v-flex>
                  <v-flex
                    xs8
                    s9
                    m10
                    style="display:flex; justify-content:center;"
                  >
                    <v-text-field
                      v-model="teamUrl"
                      readonly
                      solo
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex>
                    <v-card>
                      <v-tabs
                        v-model="contentTab"
                        background-color="primarygrad"
                        dark
                        grow
                        show-arrows
                      >
                        <v-tab v-for="item in tabsHeader" :key="item">
                          {{ item }}
                        </v-tab>
                      </v-tabs>

                      <v-tabs-items v-model="contentTab">
                        <v-tab-item>
                          <v-card color="white" flat>
                            <v-card-text>
                              <table>
                                <tr>
                                  <th>key</th>
                                  <th>value</th>
                                </tr>
                                <tr>
                                  <td>access_token</td>
                                  <td>your_api_token</td>
                                </tr>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <table>
                                <tr>
                                  <th>key</th>
                                  <th>value</th>
                                </tr>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <v-card style="background-color:#cfd1c9;">
                                <pre>
   
    {
      "name": "Team Name",
      "desc": "Team Description"
    }
                                </pre>
                              </v-card>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <table>
                                <tr>
                                  <th>key</th>
                                  <th>value</th>
                                </tr>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Add a Member</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout row mt-4>
                  <v-flex
                    xs4
                    s3
                    m2
                    style="display:flex; justify-content:center;"
                  >
                    <v-btn
                      style="padding:20px;margin-top:4px;"
                      dark
                      color="primarygrad"
                    >
                      POST
                    </v-btn>
                  </v-flex>
                  <v-flex
                    xs8
                    s9
                    m10
                    style="display:flex; justify-content:center;"
                  >
                    <v-text-field
                      v-model="memberUrl"
                      readonly
                      solo
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex>
                    <v-card>
                      <v-tabs
                        v-model="contentTab"
                        background-color="primarygrad"
                        dark
                        grow
                        show-arrows
                      >
                        <v-tab v-for="item in tabsHeader" :key="item">
                          {{ item }}
                        </v-tab>
                      </v-tabs>

                      <v-tabs-items v-model="contentTab">
                        <v-tab-item>
                          <v-card color="white" flat>
                            <v-card-text>
                              <table>
                                <tr>
                                  <th>key</th>
                                  <th>value</th>
                                </tr>
                                <tr>
                                  <td>access_token</td>
                                  <td>your_api_token</td>
                                </tr>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <table>
                                <tr>
                                  <th>key</th>
                                  <th>value</th>
                                </tr>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <v-card style="background-color:#cfd1c9;">
                                <pre>
   
    {
      "name": "Member Name",
      "email": "Member Email",
      "crmAccess": true/false,
      "skipCallAccess": true/false,
      "modifyUserAccess": true/false,
      "teamsId": [],
      "username": "Member username",
      "password": "Member Password",
      "role": "Manager/TeamManager/Agent",
      "phone": 0
    }
                                </pre>
                              </v-card>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <table>
                                <tr>
                                  <th>key</th>
                                  <th>value</th>
                                </tr>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Add a List</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout row mt-4>
                  <v-flex
                    xs4
                    s3
                    m2
                    style="display:flex; justify-content:center;"
                  >
                    <v-btn
                      style="padding:20px;margin-top:4px;"
                      dark
                      color="primarygrad"
                    >
                      POST
                    </v-btn>
                  </v-flex>
                  <v-flex
                    xs8
                    s9
                    m10
                    style="display:flex; justify-content:center;"
                  >
                    <v-text-field
                      v-model="listUrl"
                      readonly
                      solo
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex>
                    <v-card>
                      <v-tabs
                        v-model="contentTab"
                        background-color="primarygrad"
                        dark
                        grow
                        show-arrows
                      >
                        <v-tab v-for="item in tabsHeader" :key="item">
                          {{ item }}
                        </v-tab>
                      </v-tabs>

                      <v-tabs-items v-model="contentTab">
                        <v-tab-item>
                          <v-card color="white" flat>
                            <v-card-text>
                              <table>
                                <tr>
                                  <th>key</th>
                                  <th>value</th>
                                </tr>
                                <tr>
                                  <td>access_token</td>
                                  <td>your_api_token</td>
                                </tr>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <table>
                                <tr>
                                  <th>key</th>
                                  <th>value</th>
                                </tr>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <v-card style="background-color:#cfd1c9;">
                                <pre>
   
    {
      "name": "List Name",
      "desc": "List Description",
      "teamsId": "Id of team"
    }
                                </pre>
                              </v-card>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <table>
                                <tr>
                                  <th>key</th>
                                  <th>value</th>
                                </tr>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Add a Contact</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout row mt-4>
                  <v-flex
                    xs4
                    s3
                    m2
                    style="display:flex; justify-content:center;"
                  >
                    <v-btn
                      style="padding:20px;margin-top:4px;"
                      dark
                      color="primarygrad"
                    >
                      POST
                    </v-btn>
                  </v-flex>
                  <v-flex
                    xs8
                    s9
                    m10
                    style="display:flex; justify-content:center;"
                  >
                    <v-text-field
                      v-model="contactUrl"
                      readonly
                      solo
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex>
                    <v-card>
                      <v-tabs
                        v-model="contentTab"
                        background-color="primarygrad"
                        dark
                        grow
                        show-arrows
                      >
                        <v-tab v-for="item in tabsHeader" :key="item">
                          {{ item }}
                        </v-tab>
                      </v-tabs>

                      <v-tabs-items v-model="contentTab">
                        <v-tab-item>
                          <v-card color="white" flat>
                            <v-card-text>
                              <table>
                                <tr>
                                  <th>key</th>
                                  <th>value</th>
                                </tr>
                                <tr>
                                  <td>access_token</td>
                                  <td>your_api_token</td>
                                </tr>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <table>
                                <tr>
                                  <th>key</th>
                                  <th>value</th>
                                </tr>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <v-card style="background-color:#cfd1c9;">
                                <pre>
   
    {
      "name": "Contact Name",
      "email": "Contact Email",
      "phone": "Contact Phone Number",
      "secondPhone": "Contact Alternative Number",
      "companyName": "Company Name of Contact",
      "note": "Note for the Contact",
      "remarks": "Remarks for the Contact",
      "extra": "",
      "listId": "List Id of in which Contact is to be saved",
      "accountsId": "Accounts Id where Contact is to be assigned"
    }
                                </pre>
                              </v-card>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <table>
                                <tr>
                                  <th>key</th>
                                  <th>value</th>
                                </tr>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                >Assign List to a Member</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-layout row mt-4>
                  <v-flex
                    xs4
                    s3
                    m2
                    style="display:flex; justify-content:center;"
                  >
                    <v-btn
                      style="padding:20px;margin-top:4px;"
                      dark
                      color="primarygrad"
                    >
                      POST
                    </v-btn>
                  </v-flex>
                  <v-flex
                    xs8
                    s9
                    m10
                    style="display:flex; justify-content:center;"
                  >
                    <v-text-field
                      v-model="assignUrl"
                      readonly
                      solo
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex>
                    <v-card>
                      <v-tabs
                        v-model="contentTab"
                        background-color="primarygrad"
                        dark
                        grow
                        show-arrows
                      >
                        <v-tab v-for="item in tabsHeader" :key="item">
                          {{ item }}
                        </v-tab>
                      </v-tabs>

                      <v-tabs-items v-model="contentTab">
                        <v-tab-item>
                          <v-card color="white" flat>
                            <v-card-text>
                              <table>
                                <tr>
                                  <th>key</th>
                                  <th>value</th>
                                </tr>
                                <tr>
                                  <td>access_token</td>
                                  <td>your_api_token</td>
                                </tr>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <table>
                                <tr>
                                  <th>key</th>
                                  <th>value</th>
                                </tr>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <v-card style="background-color:#cfd1c9;">
                                <pre>
   
    {
      "accountsId": "ID of Member",
      "listsId": "ID of List"
    }
                                </pre>
                              </v-card>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <table>
                                <tr>
                                  <th>key</th>
                                  <th>value</th>
                                </tr>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>View Report</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout row mt-4>
                  <v-flex
                    xs4
                    s3
                    m2
                    style="display:flex; justify-content:center;"
                  >
                    <v-btn
                      style="padding:20px;margin-top:4px;"
                      dark
                      color="primarygrad"
                    >
                      POST
                    </v-btn>
                  </v-flex>
                  <v-flex
                    xs8
                    s9
                    m10
                    style="display:flex; justify-content:center;"
                  >
                    <v-text-field
                      v-model="reportUrl"
                      readonly
                      solo
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex>
                    <v-card>
                      <v-tabs
                        v-model="contentTab"
                        background-color="primarygrad"
                        dark
                        grow
                        show-arrows
                      >
                        <v-tab v-for="item in tabsHeader" :key="item">
                          {{ item }}
                        </v-tab>
                      </v-tabs>

                      <v-tabs-items v-model="contentTab">
                        <v-tab-item>
                          <v-card color="white" flat>
                            <v-card-text>
                              <table>
                                <tr>
                                  <th>key</th>
                                  <th>value</th>
                                </tr>
                                <tr>
                                  <td>access_token</td>
                                  <td>your_api_token</td>
                                </tr>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <table>
                                <tr>
                                  <th>key</th>
                                  <th>value</th>
                                </tr>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <v-card style="background-color:#cfd1c9;">
                                <pre>
   
    {
      "search": "Generate Report based on search keyword ",
      "field": "Generate Report based on field",
      "range": ["Start Date in YYYY-MM-DD format",
               "End Date in YYYY-MM-DD format"],
      "lists": ["Generate Report based on array of ListIDs"],
      "users": ["Generate Report based on array of MemberIDs"],
      "dispos": ["Generate Report based on array of dispositions"],
      "limit": 0,
      "skip": 0
    }
                                </pre>
                              </v-card>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <table>
                                <tr>
                                  <th>key</th>
                                  <th>value</th>
                                </tr>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs12 sm12 md12 style="padding: 5px;">
          <v-btn
            style="margin-top:20px; margin-left:10px; color:primary; "
            href="https://godial.stoplight.io/"
            target="_blank"
            >FULL API SPECS</v-btn
          >
        </v-flex>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumbs from "../components/breadcrumbs.vue";
export default {
  data() {
    return {
      token: "",
      snackbar: false,
      teamUrl: "https://enterprise.godial.cc/meta/api/externals/team/add",
      memberUrl: "https://enterprise.godial.cc/meta/api/externals/accounts/add",
      listUrl: "https://enterprise.godial.cc/meta/api/externals/lists/add",
      contactUrl: "https://enterprise.godial.cc/meta/api/externals/contact/add",
      assignUrl: "https://enterprise.godial.cc/meta/api/externals/lists/assign",
      reportUrl: "https://enterprise.godial.cc/meta/api/externals/log/list",
      contentTab: null,
      tabsHeader: ["Query", "Headers", "Body", "Path"],
      textTab:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
  computed: {
    ...mapState(["ENDPOINT"]),
  },

  created() {
    this.generateToken();
  },
  methods: {
    generateToken() {
      const self = this;
      var aToken = self.$store.getters.user.token;

      self.$http
        .get(`${this.ENDPOINT}/externals/token?access_token=${aToken}`)
        .then((response) => {
          this.token = response.body.id;
        })
        .catch((err) => {
          self.$swal({ type: "error", text: err.body.error.message });
        });
    },
    copy(link) {
      const self = this;
      this.$copyText(link).then(
        function(e) {
          self.snackbar = true;
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      );
    },
  },
};
</script>
<style scoped>
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}
table {
  width: 100%;
}
pre {
  overflow-x: scroll;
}
</style>
